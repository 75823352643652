<template>
	<v-layout justify-center class="main-layout" wrap :style="`background-color:${$store.state.MainColor1};`">
		<routerIcons />
		<operatingRouterIcons />
		<v-flex xs12>
			<div class="text-right mb-2">
				<v-btn @click="create" color="success">
					create
				</v-btn>
			</div>
		</v-flex>
		<v-flex xs12>
			<v-layout align-center class="mb-2">
				<h2 class="white--text">
					co-Worker
				</h2>
				<v-spacer></v-spacer>
				<v-layout align-center class="my-2">
					<DatepickerRange :picker="moneyStartpicker" @input="moneyDate"></DatepickerRange>
					<span class="mx-3 white--text"> ~ </span>
					<DatepickerRange :picker="moneyEndpicker" @input="moneyDate"></DatepickerRange>
					<v-btn small class="ml-2 excel_btn mr-2" @click="clickExport()"><img src="@/assets/excel-img2.png" />엑셀파일 다운로드</v-btn>
				</v-layout>
				<textField v-model="search" :textField="SearchTextField" />
			</v-layout>
			<datatable :datatable="datatable" :search="search" @editUser="editUser" @moneyDialog="moneyDialog" />
		</v-flex>
		<createCoWorker :data="register" @create="registerUser" @resetPassword="setDialog1.dialog = true" />
		<dialog1 :setDialog="setDialog1" @cancel="setDialog1.dialog = false" @ok="resetPassword" />
		<Money :data="money" />
		<download-excel
			class="btn btn-default"
			id="moneyExport2"
			:data="exportDatas"
			style="display:none"
			:fields="json_fields"
			type="text/csv;charset=utf8"
			worksheet="My Worksheet"
			:name="'프로텍트 월별 급여대장_' + String(moneyStartpicker.date) + '_' + String(moneyEndpicker.date)"
		>
		</download-excel>
	</v-layout>
</template>
<script>
import { routerIcons, operatingRouterIcons } from './index'
import { textField, datatable, createCoWorker, dialog1, Money, DatepickerRange } from '@/components'
export default {
	components: { textField, routerIcons, datatable, operatingRouterIcons, createCoWorker, dialog1, Money, DatepickerRange },
	data() {
		return {
			money: {
				dialog: false,
				btnText: 'work List',
				class: 'mr-2',
				interID: '',
				user: {
					Name: '',
				},
			},
			exportDatas: [],
			json_fields: {
				이름: 'users_permissions_user.Name',
				날짜: 'payment',
				세전월급: 'salary',
				성과급: 'bonus',
				건강보험: 'tax.items.tax1',
				국민연금: 'tax.items.tax2',
				요양보험: 'tax.items.tax3',
				고용보험: 'tax.items.tax4',
				소득세: 'tax.items.tax5',
				지방세: 'tax.items.tax6',
				공제총액: 'tax.items.taxSum',
				실지급액: 'tax.items.money',
			},
			setDialog1: {
				dialog: false,
				title: 'PASSWORD RESET',
				content: 'The password is changed to "TEST1234"',
				btnText: 'PASSWORD RESET',
			},
			search: '',
			SearchTextField: {
				dark: true,
				width: '200',
				placeholder: 'Search',
				['prepend-inner-icon']: 'mdi-magnify',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			datatable: {
				headers: [
					{
						text: 'NO',
						align: 'center',
						sortable: false,
						value: 'No',
					},
					{ text: 'Name', value: 'Name' },
					{ text: 'Id', value: 'username' },
					{ text: 'Email', value: 'email' },
					{ text: 'PhoneNumber', value: 'phone' },
					{ text: 'Position', value: 'position' },
					{ text: 'birthday', value: 'birthday' },
					{ text: 'StartDate', value: 'startdate' },
					{ text: 'Action', value: 'coWorkerAction', sortable: false },
				],
				items: [],
			},
			server: process.env.VUE_APP_BACKEND_URL,
			register: {
				dialog: false,
				username: '',
				password: '',
				name: '',
				phone: '',
				email: '',
				position: '',
				edit: false,
				startPicker: {
					date: null,
					rounded: true,
					errorMessage: '',
					menu: false,
					placeholder: 'start Date',
					height: 56,
				},
				endPicker: {
					date: null,
					rounded: true,
					errorMessage: '',
					menu: false,
					placeholder: 'retirement',
					height: 56,
				},
			},
			moneyStartpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'start',
				height: 48,
				width: 200,
			},
			moneyEndpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'end',
				height: 48,
				width: 200,
			},
			userID: null,
		}
	},
	methods: {
		monies(moneyday_gte, moneyday_lte) {
			const data = {
				payment_gte: moneyday_gte,
				payment_lte: moneyday_lte,
			}
			this.$store
				.dispatch('monies', data)
				.then(res => {
					console.log(res.monies)
					this.exportDatas = res.monies
				})
				.catch(err => {
					console.log({ err })
				})
		},
		moneyDate() {
			this.moneyStartpicker.menu = false
			this.moneyEndpicker.menu = false
			if (this.moneyEndpicker.date && this.moneyStartpicker.date) this.monies(this.moneyStartpicker.date, this.moneyEndpicker.date)
		},
		clickExport() {
			document.getElementById('moneyExport2').click()
		},
		moneyDialog(val, item) {
			val
			this.money.dialog = true
			this.money.interID = item.interID
			this.money.user = item
		},
		resetPassword() {
			if (this.register.edit) {
				const data = {
					id: this.userID,
					password: 'test1234',
				}
				this.$store
					.dispatch('updateUser', data)
					.then(() => {
						this.setDialog1.dialog = false
						this.register.dialog = false
					})
					.catch(err => {
						this.setDialog1.dialog = false
						this.register.dialog = false
						console.log({ err })
					})
			} else {
				alert('register edit not true')
			}
		},
		create() {
			this.register.dialog = true
			this.register.edit = true
			this.register.username = ''
			this.register.name = ''
			this.register.phone = ''
			this.register.email = ''
			this.register.birthday = ''
			this.register.position = ''
			this.register.edit = false
			this.register.startPicker.date = null
			this.userID = null
		},
		editUser(val, item) {
			val
			this.register.dialog = true
			this.register.edit = true
			this.register.username = item.username
			this.register.name = item.Name
			this.register.phone = item.phone
			this.register.email = item.email
			this.register.birthday = item.birthday.replace(/-/g, '')
			this.register.position = item.position
			this.userID = item.id
			this.register.startPicker.date = item.startdate
		},
		changetext1(data) {
			if (data == 1) {
				return '운영'
			} else if (data == 2) {
				return '직원'
			}
		},
		filesupload(event) {
			this.filedata = event.target.files[0]
			const data = {
				refId: Number(this.$store.state.userData.id),
				field: 'face',
				ref: 'user', // Model name.
				source: 'users-permissions', // Plugin name.
				file: this.filedata,
			}
			this.$store
				.dispatch('fileUpload', data)

				.then(res => {
					this.imgData = res.data.upload.url
				})
				.catch(error => {
					const errorMessage = { error }.error.networkError.result.errors[0].extensions.exception.data.message[0].messages[0].message
					console.log(errorMessage)
				})
		},
		picupload() {
			document.getElementById('pic-upload').click()
		},
		registerUser() {
			if (this.register.edit) {
				const data = {
					id: this.userID,
					phone: this.register.phone,
					name: this.register.name,
					birthday: this.register.birthday,
					position: this.register.position,
					startdate: this.register.startPicker.date,
				}
				this.$store
					.dispatch('updateUser', data)
					.then(() => {
						this.allUsers()
						this.register.dialog = false
					})
					.catch(err => {
						console.log({ err })
					})
			} else {
				const data = {
					username: this.register.username,
					email: this.register.email,
					password: this.register.password,
					phone: this.register.phone,
					Name: this.register.name,
					birthday: this.register.birthday,
					position: this.register.position,
					cls: 2,
					startdate: this.register.startPicker.date,
				}
				this.$store
					.dispatch('Register', data)
					.then(() => {
						this.allUsers()
						this.dialog = false
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		allUsers() {
			const data = {}
			this.$store
				.dispatch('allUsers', data)
				.then(res => {
					this.datatable.items = res.users
				})
				.catch(err => {
					console.log({ err })
				})
		},
	},
	created() {
		this.allUsers()
	},
}
</script>
<style></style>
