<template>
	<v-layout justify-start class="main-layout" wrap :style="`background-color:${$store.state.MainColor1};`">
		<routerIcons />
		<operatingRouterIcons />
		<v-flex xs12>
			<v-layout align-center class="mb-2">
				<h2 class="white--text">
					Theme Web
				</h2>
			</v-layout>
		</v-flex>
		<v-flex xs12>
			<v-layout align-center class="mb-2">
				<h2 class="white--text">
					Marketing Web
				</h2>
				<v-spacer></v-spacer>
				<v-dialog v-model="createDialog.dialog" scrollable max-width="300px" dark>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							color="success"
							v-bind="attrs"
							v-on="on"
							:style="
								$store.state.allowLevel.includes(Number($store.state.myData.level))
									? 'display: inline-block !important;'
									: 'display: none !important;'
							"
							v-show="$store.state.allowLevel.includes(Number($store.state.myData.level))"
							@click=";(createDialog.type = 'create'), (createWebName = '')"
						>
							create
						</v-btn>
					</template>
					<v-card>
						<v-card-title class="px-4">Create Marketing Web</v-card-title>
						<v-divider></v-divider>
						<v-card-text class="px-4 my-2 pb-0">
							<textField v-model="createWebName" :textField="textField" @input="textField['error-messages'] = ''" />
						</v-card-text>
						<v-divider></v-divider>
						<v-card-actions>
							<v-btn text @click="createDialog.dialog = false">
								Close
							</v-btn>
							<v-btn
								color="success"
								text
								@click="createWeb"
								:disabled="!$store.state.allowLevel.includes(Number($store.state.myData.level))"
							>
								Save
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-layout>
		</v-flex>
		<v-flex lg3 md4 sm6 xs12 v-for="(card, i) in websData" :key="i">
			<webCard :data="card" @list="cardList" @click="webCardDialog" />
		</v-flex>
		<webComponents :data="webComponents" />
		<dialog1 :setDialog="setDialog1" @cancel="setDialog1.dialog = false" @ok="dialogEvents" />
	</v-layout>
</template>
<script>
import { routerIcons, operatingRouterIcons } from './index'
import { dialog1, webCard, textField, webComponents } from '@/components'
export default {
	components: { routerIcons, operatingRouterIcons, dialog1, webCard, textField, webComponents },
	data() {
		return {
			webComponents: {
				dialog: false,
			},
			textField: {
				width: '100%',
				autofocus: false,
				clearable: true,
				color: '#f7f7f7ff',
				counter: true,
				dark: true,
				disabled: false,
				error: false,
				['error-messages']: '',
				height: 40,
				['hide-details']: false,
				placeholder: 'Marketing Web Name',
				solo: true,
				type: 'text',
				maxlength: 50,
			},
			createWebName: '',
			createDialog: {
				dialog: false,
				type: 'create',
			},
			setDialog1: {
				dialog: false,
				title: '',
				content: '',
				btnText: '',
			},
			server: process.env.VUE_APP_BACKEND_URL,
			websData: [],
			webId: null,
		}
	},
	methods: {
		webCardDialog(val, data) {
			val
			console.log(data.html)
			this.webComponents.dialog = true
			this.webComponents.name = data.name
			this.webComponents.id = data.id
			this.webComponents.html = data.html
		},
		dialogEvents() {
			if (this.setDialog1.btnText === 'active') {
				this.activeWeb(true)
			} else if (this.setDialog1.btnText === 'non-active') {
				this.activeWeb(false)
			} else if (this.setDialog1.btnText === 'delete') {
				this.deleteWeb()
			}
		},
		cardList(val, item, data) {
			this.webId = data.id
			val
			if (item === 0) {
				this.setDialog1.dialog = true
				this.setDialog1.title = 'active web'
				this.setDialog1.content = 'Are you really sure to active "' + data.name + '" web?'
				this.setDialog1.btnText = 'active'
			} else if (item === 1) {
				this.setDialog1.dialog = true
				this.setDialog1.title = 'not-active web'
				this.setDialog1.content = 'Are you really sure to not-active "' + data.name + '" web?'
				this.setDialog1.btnText = 'not-active'
			} else if (item === 2) {
				this.createDialog.dialog = true
				this.createDialog.type = 'edit'
				this.createWebName = data.name
			} else if (item === 3) {
				this.setDialog1.dialog = true
				this.setDialog1.title = 'delete web'
				this.setDialog1.content = 'Are you really sure to delete "' + data.name + '" web?'
				this.setDialog1.btnText = 'delete'
			}
		},
		createComponent() {
			const data = {
				background: this.background,
				backgroundColor: this.backgroundColor,
				titleColor: this.titleColor,
				contentColor: this.contentColor,
				width: this.width,
				height: this.height,
				align: this.align,
				justify: this.justify,
				index: this.index,
				overlay: this.overlay,
				type: this.type,
				typeNumber: this.typeNumber,
				show: true,
				web: this.web,
			}
			this.$store
				.dispatch('createComponent', data)
				.then(res => {
					console.log(res)
				})
				.catch(err => {
					console.log({ err })
				})
		},
		updateComponent() {
			const data = {
				id: this.webId,
				background: this.background,
				backgroundColor: this.backgroundColor,
				titleColor: this.titleColor,
				contentColor: this.contentColor,
				width: this.width,
				height: this.height,
				align: this.align,
				justify: this.justify,
				index: this.index,
				overlay: this.overlay,
				type: this.type,
				typeNumber: this.typeNumber,
				show: true,
				web: this.web,
			}
			this.$store
				.dispatch('updateComponent', data)
				.then(res => {
					console.log(res)
				})
				.catch(err => {
					console.log({ err })
				})
		},
		createWeb() {
			if (this.createDialog.type === 'create') {
				var newURL = window.location.protocol + '//' + window.location.host
				const data = {
					name: this.createWebName,
					url: newURL,
					show: true,
					type: '1',
					active: false,
				}
				this.$store
					.dispatch('createWeb', data)
					.then(res => {
						this.webs()
						console.log(res)
					})
					.catch(err => {
						console.log({ err })
					})
			} else {
				this.updateWeb()
			}
		},
		updateWeb() {
			var newURL = window.location.protocol + '//' + window.location.host
			const data = {
				id: this.webId,
				name: this.createWebName,
				url: newURL,
				type: '1',
			}
			this.$store
				.dispatch('updateWeb', data)
				.then(() => {
					this.webs()
				})
				.catch(err => {
					console.log({ err })
				})
		},
		deleteWeb() {
			const data = {
				id: this.webId,
				show: false,
			}
			this.$store
				.dispatch('updateWeb', data)
				.then(() => {
					this.webs()
				})
				.catch(err => {
					console.log({ err })
				})
		},
		activeWeb(Boolean) {
			const data = {
				id: this.webId,
				active: Boolean,
			}
			this.$store
				.dispatch('updateWeb', data)
				.then(() => {
					this.webs()
				})
				.catch(err => {
					console.log({ err })
				})
		},
		components() {
			const data = {}
			this.$store
				.dispatch('components', data)
				.then(res => {
					console.log(res)
				})
				.catch(err => {
					console.log({ err })
				})
		},
		webs() {
			const data = {
				show: true,
			}
			this.$store
				.dispatch('webs', data)
				.then(res => {
					this.websData = res.webs
					this.setDialog1.dialog = false
					this.createDialog.dialog = false
					console.log(res)
				})
				.catch(err => {
					console.log({ err })
				})
		},
		webCreateDialog() {},
		picupload() {
			document.getElementById('pic-upload').click()
		},
	},
	created() {
		this.webs()
	},
}
</script>
<style></style>
