<template>
	<v-layout :style="`background-color:${$store.state.MainColor1};`" class="main-layout" justify-center wrap>
		<routerIcons />
		<v-flex sm12 xs12 class="mb-4" v-show="$store.state.workList.length > 0">
			<div :class="$vuetify.breakpoint.smAndDown ? '' : 'mx-2'">
				<v-layout align-center class="my-2">
					<h2 class="white--text">
						프로텍트 JIRA
					</h2>
					<v-spacer></v-spacer>
					<div style="max-width:200px; width:100%">
						<v-select
							hideDetails
							item-color="PointColor2"
							class="ml-2"
							:items="['일반', '공지']"
							solo
							dense
							full-width
							:value="workType"
							v-model="workType"
							color="PointColor2"
						/>
					</div>
					<div style="max-width:200px; width:100%">
						<v-select
							@change="myWorkListSelect"
							hideDetails
							item-color="PointColor2"
							class="ml-2"
							:items="filter_items_work.length > 0 ? filter_items_work : workList"
							solo
							dense
							full-width
							:value="workSelect"
							color="PointColor2"
							><template v-slot:prepend-item>
								<v-list-item>
									<v-text-field hide-details filled rounded placeholder="search" @input="searchFruit_works" class="px-2 pb-2" />
								</v-list-item>

								<v-divider class=""></v-divider> </template
						></v-select>
					</div>
					<div style="max-width:200px; width:100%">
						<v-select
							hideDetails
							item-color="PointColor2"
							class="ml-2"
							:items="filter_items.length > 0 ? filter_items : jiraList"
							solo
							dense
							full-width
							return-object
							placeholder="version UP 선택"
							item-text="content"
							clearable
							v-model="jira"
							color="PointColor2"
						>
							<template v-slot:prepend-item>
								<v-list-item>
									<v-text-field hide-details filled rounded placeholder="search" @input="searchFruit" class="px-2 pb-2" />
								</v-list-item>

								<v-divider class=""></v-divider> </template
						></v-select>
					</div>
				</v-layout>
				<v-layout wrap>
					<v-flex xs11>
						<v-textarea hideDetails dark v-model="work" solo placeholder="*업무 내용을 입력해주세요."></v-textarea>
						<textField v-model="workUrl" :textField="workTextField" @keydown.enter="updateTodolist" />
						<h4 class="white--text mt-1">version: v{{ jira === null ? '1' : jira.list.length + 2 }}.0</h4>
					</v-flex>
					<v-flex xs1>
						<div class="pl-2">
							<btn :btn="workSaveBtn" btn_txt="저장" @click="updateTodolist" />
						</div>
					</v-flex>
				</v-layout>
				<v-divider dark class="my-4"></v-divider>
				<v-layout align-center class="my-2" justify-end>
					<btn :btn="workBtn" btn_txt="refresh" @click="() => todolists(itemsPerPage, (page - 1) * itemsPerPage)" />
					<div style="max-width:200px; width:100%">
						<v-select
							hideDetails
							item-color="PointColor2"
							class="ml-2"
							:items="filter_items_planner.length > 0 ? filter_items_planner : users"
							solo
							dense
							full-width
							return-object
							placeholder="기획자 필터"
							item-text="Name"
							clearable
							v-model="plannerFilter"
							color="PointColor2"
						>
							<template v-slot:prepend-item>
								<v-list-item>
									<v-text-field hide-details filled rounded placeholder="search" @input="searchFruit_planner" class="px-2 pb-2" />
								</v-list-item>

								<v-divider class=""></v-divider> </template
						></v-select>
					</div>
					<div style="max-width:200px; width:100%">
						<v-select
							hideDetails
							item-color="PointColor2"
							class="ml-2"
							:items="filter_items_dev.length > 0 ? filter_items_dev : users"
							solo
							dense
							full-width
							return-object
							placeholder="개발자 필터"
							item-text="Name"
							clearable
							v-model="devFilter"
							color="PointColor2"
						>
							<template v-slot:prepend-item>
								<v-list-item>
									<v-text-field hide-details filled rounded placeholder="search" @input="searchFruit_dev" class="px-2 pb-2" />
								</v-list-item>

								<v-divider class=""></v-divider> </template
						></v-select>
					</div>
					<div style="max-width:200px; width:100%">
						<v-select
							hideDetails
							item-color="PointColor2"
							class="ml-2"
							:items="['기획', '개발', 'QA', '완료']"
							solo
							dense
							full-width
							return-object
							placeholder="상태 필터"
							clearable
							v-model="statusFilter"
							color="PointColor2"
						></v-select>
					</div>
					<div style="padding-left:10px; max-width:200px; width:100%">
						<v-checkbox dark color="indigo" v-model="typeFilter" label="공지만 보기"></v-checkbox>
					</div>
					<v-spacer></v-spacer>
					<textField v-model="workSearch" :textField="SearchTextField" @keydown.enter="searchActive" />
				</v-layout>
				<datatable
					:datatable="workDatatable"
					@confirm="confirmWork"
					@workAction="workActionDialog"
					@workActionDelete="workActionDeleteDialog"
					@updateComment="updateComment"
					@deleteComment="deleteCommentDialog"
					@devSetting="devSetting"
					@deleteWork="deleteWork"
					@pagination="pagination"
					@expandRow="expandRow"
				>
				</datatable>
			</div>
		</v-flex>
		<v-dialog v-model="devDialog">
			<v-layout justify-end style="background-color: black;">
				<v-btn icon @click="devDialog = !devDialog" dark>
					<v-icon>
						mdi-close
					</v-icon>
				</v-btn>
			</v-layout>
			<datatable :datatable="devList" @devSelect="devSelect"> </datatable>
		</v-dialog>
		<dialog1 :setDialog="setDialog1" @cancel="setDialog1.dialog = false" @ok="WorkData" />
		<div id="todoActive" @click="() => todolists(itemsPerPage, (page - 1) * itemsPerPage)"></div>
	</v-layout>
</template>
<script>
import { routerIcons } from './index'
import { datatable, textField, btn, dialog1 } from '@/components'

export default {
	components: {
		datatable,
		textField,
		routerIcons,
		// DatepickerRange,
		dialog1,
		btn,
	},
	computed: {
		checkProgress() {
			let val = 0
			val = (this.workCheckDatatable.items.filter(x => x.status === 3).length / this.workCheckDatatable.items.length) * 100
			return val
		},
	},
	data: () => ({
		devDialog: false,
		todoListId: null,
		actionItem: {},
		setDialog1: {
			dialog: false,
			title: '',
			content: '',
			btnText: '',
			type: '',
		},
		workDatatable: {
			headers: [
				{ text: '넘버', value: 'No_click_expand', sortable: false },
				{ text: '업무내용', value: 'content_click_expand', sortable: false, width: '45%' },
				{ text: '기획자', value: 'user' },
				{ text: '개발자', value: 'devIDName' },
				{ text: '컨펌', value: 'confirm', width: '15%' },
				{ text: '상태', value: 'status' },
				{ text: '액션', value: 'workAction', sortable: false },
			],
			items: [],
			hidedefaultfooter: false,
			hidedefaultheader: false,
			singleExpand: false,
			expanded: [],
			['show-expand']: true,
			key: 'id',
			comment: '',
		},
		devList: {
			headers: [
				{ text: 'No', value: 'No', sortable: false },
				{ text: '이름', value: 'Name' },
				{ text: '직책', value: 'position' },
				{ text: '액션', value: 'devList', sortable: false },
			],
			itemPerPage: 15,
			items: [],
			hidedefaultfooter: false,
			hidedefaultheader: false,
		},
		workCheckDatatable: {
			headers: [
				{ text: 'No', value: 'No', sortable: false },
				{ text: 'Title', value: 'title' },
				{ text: 'DeadLine', value: 'deadline' },
				{ text: 'User', value: 'user' },
				{ text: 'Status', value: 'status' },
				{ text: 'Action', value: 'workCheckAction', sortable: false },
			],
			items: [],
			hidedefaultfooter: false,
			hidedefaultheader: false,
			singleExpand: true,
			expanded: [],
			['show-expand']: true,
			key: 'created_at',
			comment: '',
		},
		endTimepicker: {
			date: null,
			errorMessage: '',
			menu: false,
			backgroundColor: 'PointColor2',
			height: 48,
			placeholder: 'deadline',
			width: 150,
		},
		workStartpicker: {
			date: null,
			errorMessage: '',
			menu: false,
			placeholder: 'start',
			height: 48,
		},
		workEndpicker: {
			date: null,
			errorMessage: '',
			menu: false,
			placeholder: 'end',
			height: 48,
		},
		workCheckStartpicker: {
			date: null,
			errorMessage: '',
			menu: false,
			placeholder: 'start',
			height: 48,
		},
		workCheckEndpicker: {
			date: null,
			errorMessage: '',
			menu: false,
			placeholder: 'end',
			height: 48,
		},
		workSaveBtn: {
			dark: true,
			color: 'PointColor1',
			height: '150px',
			block: true,
			width: '100%',
			class: '',
		},
		workBtn: {
			dark: true,
			color: 'PointColor1',
			height: 48,
			class: 'mr-2',
		},
		work: '',
		workTextField: {
			['background-color']: 'PointColor2',
			dark: true,
			autofocus: false,
			width: '100%',
			['error-messages']: '',
			placeholder: '*기획서 URL을 입력해주세요.',
			solo: true,
			type: 'text',
			maxlength: 255,
		},
		DepartmentSearch: '',
		workSearch: '',
		workCheckSearch: '',
		SearchTextField: {
			dark: true,
			width: '200',
			placeholder: 'Search',
			['prepend-inner-icon']: 'mdi-magnify',
			solo: true,
			type: 'text',
			maxlength: 255,
		},
		datatable: {
			headers: [
				{
					text: 'NO',
					align: 'center',
					sortable: false,
					value: 'No',
				},
				{ text: 'Name', value: 'Name' },
				{ text: 'Position', value: 'position' },
				{ text: 'PhoneNumber', value: 'phone' },
				{ text: 'get to work', value: 'work' },
				{ text: 'leave work', value: 'Leave' },
				{ text: 'etc', value: 'notes' },
			],
			items: [],
		},
		Projectsearch: '',

		workItem: {},
		workItemComment: {},
		workList: [],
		interID: null,
		workSelect: '',
		workType: '일반',
		jiraList: [],
		filter_items: [],
		jira: null,
		workUrl: '',
		workData: null,
		itemsPerPage: 10,
		page: 1,
		users: [],
		filter_items_work: [],
		filter_items_planner: [],
		filter_items_dev: [],
		plannerFilter: null,
		devFilter: null,
		statusFilter: null,
		typeFilter: false,
	}),
	watch: {
		statusFilter() {
			if (this.interID) {
				this.page = 1
				this.workDatatable.page = 1
				this.todolists(this.itemsPerPage, (this.page - 1) * this.itemsPerPage)
			}
		},
		typeFilter() {
			if (this.interID) {
				this.page = 1
				this.workDatatable.page = 1
				this.todolists(this.itemsPerPage, (this.page - 1) * this.itemsPerPage)
			}
		},
		plannerFilter() {
			if (this.interID) {
				this.page = 1
				this.workDatatable.page = 1
				this.todolists(this.itemsPerPage, (this.page - 1) * this.itemsPerPage)
			}
		},
		devFilter() {
			if (this.interID) {
				this.page = 1
				this.workDatatable.page = 1
				this.todolists(this.itemsPerPage, (this.page - 1) * this.itemsPerPage)
			}
		},
	},
	methods: {
		expandRow(ev) {
			const expandedArr = this.workDatatable.expanded
			const foundIndex = expandedArr.findIndex(exArr => exArr.id === ev.id)

			if (foundIndex === -1) this.workDatatable.expanded.push(ev)
			else this.workDatatable.expanded.splice(foundIndex, 1)
		},
		searchActive() {
			this.$store.state.loading = true
			this.page = 1
			this.workDatatable.page = 1
			const data = {
				interID: this.interID,
				limit: this.itemsPerPage,
				start: (this.page - 1) * this.itemsPerPage,
				content_contains: this.workSearch,
			}
			this.$store.dispatch('todolists', data).then(res => {
				this.workDatatable.total = res.todolistsConnection.aggregate.count
				this.$store.state.loading = false
				const array = []
				this.$store.dispatch('allUsers', {}).then(resUser => {
					for (let index = 0; index < res.todolists.length; index++) {
						const el = res.todolists[index]
						if (el.plannerID) {
							const index1 = resUser.users.map(x => x.id).indexOf(el.plannerID)
							el.user = resUser.users[index1].Name
						}
						if (el.devID) {
							const index2 = resUser.users.map(x => x.id).indexOf(el.devID)
							el.devIDName = resUser.users[index2].Name
						}
						array.push(el)
					}
					this.workDatatable.items = array
					this.jiraList = array
				})
				this.$store.state.loading = false
			})
		},
		async pagination(item) {
			if (this.interID) {
				this.itemsPerPage = item.itemsPerPage
				this.page = item.page
				this.todolists(this.itemsPerPage, (this.page - 1) * this.itemsPerPage)
			}
		},
		WorkData() {
			if (this.setDialog1.type === 'delete-work') {
				const data = {
					id: this.workData.id,
					show: false,
				}
				this.$store.dispatch('updateTodolist', data).then(() => {
					this.setDialog1.dialog = false
					this.todolists(this.itemsPerPage, (this.page - 1) * this.itemsPerPage)
				})
			} else if (this.setDialog1.type === 'planning-confirm-work') {
				let array = []

				if (this.workData.planningConfirm) array = this.workData.planningConfirm
				if (this.workData.planningConfirm.indexOf(this.$store.state.myData.id) === -1) array.push(this.$store.state.myData.id)
				const data = {
					id: this.workData.id,
					planningConfirm: array,
				}
				if (
					array.indexOf(this.workData.plannerID) > -1 &&
					array.indexOf(this.workData.devID) > -1 &&
					array.indexOf(this.workData.completeID) > -1
				) {
					data.planning = this.$moment().format('YYYY-MM-DD')
					data.status = 'dev'
				}
				this.$store.dispatch('updateTodolist', data).then(() => {
					this.setDialog1.dialog = false
					this.todolists(this.itemsPerPage, (this.page - 1) * this.itemsPerPage)
				})
			} else if (this.setDialog1.type === 'dev-confirm-work') {
				let array = []
				array.push(this.$store.state.myData.id)
				const data = {
					id: this.workData.id,
					devConfirm: array,
				}
				data.dev = this.$moment().format('YYYY-MM-DD')
				data.status = 'qa'
				this.$store.dispatch('updateTodolist', data).then(() => {
					this.setDialog1.dialog = false
					this.todolists(this.itemsPerPage, (this.page - 1) * this.itemsPerPage)
				})
			} else if (this.setDialog1.type === 'qa-confirm-work') {
				let array = []
				if (this.workData.qaConfirm) array = this.workData.qaConfirm
				if (this.workData.qaConfirm.indexOf(this.$store.state.myData.id) === -1) array.push(this.$store.state.myData.id)

				const data = {
					id: this.workData.id,
					qaConfirm: array,
				}
				if (
					array.indexOf(this.workData.plannerID) > -1 &&
					array.indexOf(this.workData.devID) > -1 &&
					array.indexOf(this.workData.completeID) > -1
				) {
					data.complete = this.$moment().format('YYYY-MM-DD')
					data.status = 'complete'
				}
				this.$store.dispatch('updateTodolist', data).then(() => {
					this.setDialog1.dialog = false
					this.todolists(this.itemsPerPage, (this.page - 1) * this.itemsPerPage)
				})
			} else if (this.setDialog1.type === 'no-show-comment') {
				this.deleteComment(this.workItem, this.workItemComment)
			}
		},
		workStatus(data) {
			if (data === 'planning') {
				return '기획'
			} else if (data === 'dev') {
				return '개발'
			} else if (data === 'qa') {
				return 'QA'
			} else if (data === 'complete') {
				return '완료'
			}
		},
		workStatus_convert(data) {
			if (data === '기획') {
				return 'planning'
			} else if (data === '개발') {
				return 'dev'
			} else if (data === 'QA') {
				return 'qa'
			} else if (data === '완료') {
				return 'complete'
			}
		},
		confirmWork(ev, item) {
			ev
			this.setDialog1.dialog = true
			this.setDialog1.title = `${this.workStatus(item.status)} 컨펌`
			this.setDialog1.type = `${item.status}-confirm-work`
			this.setDialog1.content = `정말로 ${this.workStatus(item.status)}을 컨펌을 하시겠습니까?`
			this.setDialog1.btnText = '컨펌'
			this.workData = item
		},
		deleteWork(ev, item) {
			ev
			this.setDialog1.dialog = true
			this.setDialog1.title = 'Work 삭제'
			this.setDialog1.type = 'delete-work'
			this.setDialog1.content = '정말로 Work를 삭제하시겠습니까?'
			this.setDialog1.btnText = '삭제'
			this.workData = item
		},
		devSelect(ev, item) {
			ev
			const data = {
				id: this.$store.state.devItem.id,
				devID: item.id,
			}
			this.$store.dispatch('updateTodolist', data).then(() => {
				this.devDialog = false
				this.todolists(this.itemsPerPage, (this.page - 1) * this.itemsPerPage)
			})
		},
		devSetting(ev, item) {
			this.devDialog = true
			this.$store.state.devItem = item
			this.$store.state.loading = true
			ev
			// this.select
			this.$store.dispatch('allUsers').then(res => {
				this.devList.items = res.users
				this.$store.state.loading = false
			})
		},
		searchFruit_planner(val) {
			if (val) {
				if (this.users[0].Name) {
					this.filter_items_planner = JSON.parse(JSON.stringify(this.users.filter(fruit => fruit.Name.indexOf(val) !== -1)))
				}
			} else {
				this.filter_items_planner = JSON.parse(JSON.stringify(this.users))
			}
		},
		searchFruit_works(val) {
			if (val) {
				this.filter_items_work = JSON.parse(JSON.stringify(this.workList.filter(fruit => fruit.indexOf(val) !== -1)))
			} else {
				this.filter_items_work = JSON.parse(JSON.stringify(this.workList))
			}
		},
		searchFruit_dev(val) {
			if (val) {
				if (this.users[0].Name) {
					this.filter_items_dev = JSON.parse(JSON.stringify(this.users.filter(fruit => fruit.Name.indexOf(val) !== -1)))
				}
			} else {
				this.filter_items_dev = JSON.parse(JSON.stringify(this.users))
			}
		},
		searchFruit(val) {
			if (val) {
				if (this.jiraList[0].content) {
					this.filter_items = JSON.parse(JSON.stringify(this.jiraList.filter(fruit => fruit.content.indexOf(val) !== -1)))
				} else if (this.jiraList[0].title) {
					this.filter_items = JSON.parse(JSON.stringify(this.jiraList.filter(fruit => fruit.title.indexOf(val) !== -1)))
				} else {
					this.filter_items = JSON.parse(JSON.stringify(this.jiraList.filter(fruit => fruit.indexOf(val) !== -1)))
				}
			} else {
				this.filter_items = JSON.parse(JSON.stringify(this.jiraList))
			}
		},
		workActionDelete(item) {
			this.$store.state.loading = true
			const data = { interID: this.interID }
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					const lists = res.todolists[0].list
					lists.splice(res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at), 1)
					const data = {
						id: this.todoListId,
						list: lists,
					}
					this.$store
						.dispatch('updateTodolist', data)
						.then(() => {
							this.$store.state.loading = false
							this.todolists(this.itemsPerPage, (this.page - 1) * this.itemsPerPage)
						})
						.catch(err => {
							this.$store.state.loading = false
							console.log({ err })
						})
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		workAction(item) {
			this.$store.state.loading = true
			const data = { interID: this.interID }
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					const lists = res.todolists[0].list
					if (lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status === 1) {
						lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status = 2
					} else if (lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status === 2) {
						lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status = 3
						lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].checker.push({
							name: this.$store.state.myData.Name,
							email: this.$store.state.myData.email,
						})
					} else if (lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status === 3) {
						lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status = 1
					}
					const data = {
						id: this.todoListId,
						list: lists,
					}
					this.$store
						.dispatch('updateTodolist', data)
						.then(() => {
							this.$store.state.loading = false
							this.todolists(this.itemsPerPage, (this.page - 1) * this.itemsPerPage)
						})
						.catch(err => {
							this.$store.state.loading = false
							console.log({ err })
						})
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		todolists(limit, start) {
			if (this.interID) {
				this.$store.state.loading = true
				const data = { interID: this.interID, limit: limit, start: start }
				if (this.statusFilter) data.status = this.workStatus_convert(this.statusFilter)
				if (this.typeFilter) data.type = '공지'
				if (this.devFilter) data.devID = this.devFilter.id
				if (this.plannerFilter) data.plannerID = this.plannerFilter.id
				if (this.workSearch) data.content_contains = this.workSearch
				this.$store
					.dispatch('todolists', data)
					.then(res => {
						this.workDatatable.total = res.todolistsConnection.aggregate.count
						this.$store.state.loading = false
						const array = []
						this.$store.dispatch('allUsers', {}).then(resUser => {
							this.users = resUser.users
							for (let index = 0; index < res.todolists.length; index++) {
								const el = res.todolists[index]
								if (el.plannerID) {
									const index1 = resUser.users.map(x => x.id).indexOf(el.plannerID)
									el.user = resUser.users[index1].Name
								}
								if (el.devID) {
									const index2 = resUser.users.map(x => x.id).indexOf(el.devID)
									el.devIDName = resUser.users[index2].Name
								}
								array.push(el)
							}
							this.workDatatable.items = array
							this.jiraList = array
						})
					})
					.catch(err => {
						this.$store.state.loading = false
						console.log({ err })
					})
			}
		},
		updateTodolist() {
			if (this.work === '') {
				return alert('업무 내용을 입력해주세요.')
			}
			// 기획서 URL 필수 아니게 수정
			// if (this.workUrl === '') {
			// 	return alert('기획서 URL을 입력해주세요.')
			// }
			// planning
			// dev
			// qa
			// complete
			this.$store.state.loading = true
			if (this.jira) {
				const list = this.jira.list
				list.push({
					comment: this.jira.comment,
					content: this.jira.content,
					version: this.jira.version,
					calendar: this.jira.calendar,
					complete: this.jira.complete,
					completeID: this.jira.completeID,
					dev: this.jira.dev,
					devConfirm: this.jira.devConfirm,
					devID: this.jira.devID,
					devIDName: this.jira.devIDName,
					plannerID: this.jira.plannerID,
					planning: this.jira.planning,
					planningConfirm: this.jira.planningConfirm,
					qa: this.jira.qa,
					qaConfirm: this.jira.qaConfirm,
					status: this.jira.status,
					url: this.jira.url,
					user: this.jira.user,
				})
				const data = {
					list: list,
					version: `v${this.jira.list.length + 1}.0`,
					content: this.work,
					url: this.workUrl,
					type: this.workType,
					status: 'planning',
					planning: this.$moment().format('YYYY-MM-DD'),
					comment: [],
					plannerID: this.$store.state.myData.id,
					completeID: '1',
					devConfirm: [],
					qaConfirm: [],
					planningConfirm: [],
					id: this.jira.id,
				}
				this.$store
					.dispatch('updateTodolist', data)
					.then(() => {
						this.jira = null
						this.$store.state.loading = false
						this.work = ''
						this.workUrl = ''
						this.workType = '일반'
						this.todolists(this.itemsPerPage, (this.page - 1) * this.itemsPerPage)
					})
					.catch(err => {
						this.$store.state.loading = false
						console.log({ err })
					})
			} else {
				const data = {
					interID: this.interID,
					list: [],
					version: 'v1.0',
					content: this.work,
					url: this.workUrl,
					type: this.workType,
					status: 'planning',
					planning: this.$moment().format('YYYY-MM-DD'),
					comment: [],
					plannerID: this.$store.state.myData.id,
					completeID: '1',
					devConfirm: [],
					qaConfirm: [],
					planningConfirm: [],
				}
				this.$store
					.dispatch('createTodolist', data)
					.then(() => {
						this.$store.state.loading = false
						this.work = ''
						this.workUrl = ''
						this.workType = '일반'
						this.todolists(this.itemsPerPage, (this.page - 1) * this.itemsPerPage)
					})
					.catch(err => {
						this.$store.state.loading = false
						console.log({ err })
					})
			}
		},
		workCouselor(val) {
			let start = this.$moment(this.workStartpicker.date).format('YYMMDD')

			let startDate = new Date(this.workStartpicker.date)
			let end = new Date(startDate.setDate(startDate.getDate() + 60))
			let endDate = this.$moment(end).format('YYMMDD')

			val = this.$moment(val).format('YYMMDD') < endDate && this.$moment(val).format('YYMMDD') > start
			return val
		},
		workActionDialog(val, item) {
			console.log('test : ', val, item)
			val
			this.actionItem = item
			this.setDialog1.dialog = true
			if (item.status === 1) {
				this.setDialog1.title = 'report to Leader'
				this.setDialog1.type = 'report'
				this.setDialog1.content = 'Are you really sure to report to ' + item.title + '?'
				this.setDialog1.btnText = 'report'
			} else if (item.status === 3) {
				this.setDialog1.title = 'get back to working'
				this.setDialog1.type = 'return'
				this.setDialog1.content = 'Are you really sure to get back ' + item.title + '?'
				this.setDialog1.btnText = 'get back'
			}
		},
		workActionDeleteDialog(val, item) {
			val
			this.deleteItem = item
			this.setDialog1.dialog = true
			this.setDialog1.title = 'Work List Delete'
			this.setDialog1.type = 'workActionDelete'
			this.setDialog1.content = 'Are you really sure to delete ' + item.title + '?'
			this.setDialog1.btnText = 'delete'
		},
		myWorkListSelect(val) {
			const workList = []
			this.$store.dispatch('me').then(res => {
				const sorting = res.teams.sort(function(a, b) {
					if (a.created_at < b.created_at) return 1
					if (a.created_at === b.created_at) return 0
					if (a.created_at > b.created_at) return -1
				})
				sorting.forEach(team => {
					if (team.calendars.length > 0) {
						team.calendars.forEach(calendar => {
							workList.push(calendar)
						})
					}
				})
				this.workList = workList.map(x => x.name)
				const index = workList.map(x => x.name).indexOf(val)
				this.workSelect = val
				this.interID = workList[index].interID
				this.todolists(this.itemsPerPage, (this.page - 1) * this.itemsPerPage)
			})
		},
		endDate() {
			this.endTimepicker.errorMessage = ''
			this.endTimepicker.menu = false
		},
		workDate() {
			this.workStartpicker.menu = false
			this.workEndpicker.menu = false
			this.workDatatable.items = this.workList.filter(
				x => new Date(x.deadline) >= new Date(this.workStartpicker.date) && new Date(x.deadline) <= new Date(this.workEndpicker.date),
			)
		},
		workCheckDate() {
			this.workCheckStartpicker.menu = false
			this.workCheckEndpicker.menu = false
			this.workCheckDatatable.items = this.workList.filter(
				x =>
					new Date(x.deadline) >= new Date(this.workCheckStartpicker.date) &&
					new Date(x.deadline) <= new Date(this.workCheckEndpicker.date) &&
					x.status >= 2 &&
					x.email !== this.$store.state.myData.email,
			)
		},
		dialogEvents() {
			if (this.setDialog1.type === 'workActionDelete') {
				this.workActionDelete(this.deleteItem)
			} else if (this.setDialog1.type === 'report' || this.setDialog1.type === 'return') {
				this.workAction(this.actionItem)
			} else if (this.setDialog1.type === 'no-show-comment') {
				this.deleteComment(this.workItem, this.workItemComment)
			} else if (this.setDialog1.type === 'Check-the-Work') {
				this.workAction(this.workItem)
			}
		},
		deleteCommentDialog(val, item, chat) {
			val
			this.workItem = item
			this.workItemComment = chat
			this.setDialog1.dialog = true
			this.setDialog1.title = 'No-show comment'
			this.setDialog1.type = 'no-show-comment'
			this.setDialog1.content = 'Are you really sure to no-show this comment? - "' + chat.comment + '"'
			this.setDialog1.btnText = 'no-show'
		},
		deleteComment(item, chat) {
			this.$store.state.loading = true
			const data = { id: item.id }
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					const lists = res.todolists[0].comment
					const commentIndex = lists.map(y => y.created_at).indexOf(chat.created_at)
					lists[commentIndex].show = !lists[commentIndex].show
					const data = {
						id: item.id,
						comment: lists,
					}
					this.$store
						.dispatch('updateTodolist', data)
						.then(() => {
							this.setDialog1.dialog = false
							this.$store.state.loading = false
							this.todolists(this.itemsPerPage, (this.page - 1) * this.itemsPerPage)
						})
						.catch(err => {
							this.$store.state.loading = false
							console.log({ err })
						})
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		updateComment(val, item) {
			if (this.workDatatable.comment !== '') {
				val
				this.$store.state.loading = true
				const data = { id: item.id }
				this.$store
					.dispatch('todolists', data)
					.then(res => {
						const comments = res.todolists[0].comment
						comments.push({
							created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
							comment: this.workDatatable.comment,
							user: this.$store.state.myData.Name,
							email: this.$store.state.myData.email,
							show: true,
							face: this.$store.state.myData.face,
						})
						const data = {
							id: item.id,
							comment: comments,
						}
						this.$store
							.dispatch('updateTodolist', data)
							.then(() => {
								this.$store.state.loading = false
								this.workDatatable.comment = ''
								this.todolists(this.itemsPerPage, (this.page - 1) * this.itemsPerPage)
							})
							.catch(err => {
								this.$store.state.loading = false
								console.log({ err })
							})
					})
					.catch(err => {
						this.$store.state.loading = false
						console.log({ err })
					})
			}
		},
	},
	created() {
		setTimeout(() => {
			this.myWorkListSelect(this.$store.state.workList[this.$store.state.workList.length - 1])
		}, 1000)
	},
}
</script>
