<template>
	<v-layout justify-center class="main-layout" wrap :style="`background-color:${$store.state.MainColor1};`">
		<routerIcons />
		<operatingRouterIcons />
		<v-flex xs12>
			<v-layout justify-center v-if="$vuetify.breakpoint.smAndUp">
				<v-card dark class="mr-2">
					<div>
						<v-img style="margin:0 auto" :src="server + logoUrl" max-height="200" min-height="200" max-width="200"></v-img>
					</div>
					<div class="text-center px-4">
						<v-btn color="PointColor2" @click="LogoUpload">Logo Change</v-btn>
					</div>
				</v-card>
				<v-card dark class="pa-4 mr-2">
					<div>
						<h3 class="mb-2">
							how many times a month?
						</h3>
						<v-select item-color="PointColor2" :items="ceoReports" v-model="ceoReport" label="ceoReport" outlined dense></v-select>
						<v-select item-color="PointColor2" :items="teamReports" v-model="teamReport" label="teamReport" outlined dense></v-select>
						<v-select item-color="PointColor2" :items="selfReports" v-model="selfReport" label="selfReport" outlined dense></v-select>
					</div>
				</v-card>
				<v-card dark class="pa-4">
					<v-layout>
						<colorPicker v-model="color.MainColor1" :color_picker="color_picker_m1" />
						<colorPicker v-model="color.MainColor2" :color_picker="color_picker_m2" />
						<colorPicker v-model="color.FontColor1" :color_picker="color_picker_f1" />
						<colorPicker v-model="color.FontColor2" :color_picker="color_picker_f2" />
					</v-layout>
					<v-layout class="mt-2">
						<colorPicker v-model="color.PointColor1" :color_picker="color_picker_p1" />
						<colorPicker v-model="color.PointColor2" :color_picker="color_picker_p2" />
						<colorPicker v-model="color.PointColor3" :color_picker="color_picker_p3" />
					</v-layout>
					<v-layout class="mt-4" justify-end>
						<v-btn color="PointColor1" @click="ResetColorActive" class="mr-2" large>
							Reset
						</v-btn>
						<v-btn color="success" large @click="ApplyColor">
							apply
						</v-btn>
					</v-layout>
				</v-card>
			</v-layout>
			<v-layout justify-center v-else wrap>
				<v-flex xs12>
					<v-card dark class="mb-2">
						<div>
							<v-img style="margin:0 auto" :src="server + logoUrl" max-height="200" min-height="200" max-width="200"></v-img>
						</div>
						<div class="text-center px-4 pb-4">
							<v-btn color="PointColor2" @click="LogoUpload">Logo Change</v-btn>
						</div>
					</v-card>
				</v-flex>
				<v-flex xs12>
					<v-card dark class="pa-2 mb-2">
						<div>
							<h3 class="mb-2">
								how many times a month?
							</h3>
							<v-select item-color="PointColor2" :items="ceoReports" v-model="ceoReport" label="ceoReport" outlined dense></v-select>
							<v-select item-color="PointColor2" :items="teamReports" v-model="teamReport" label="teamReport" outlined dense></v-select>
							<v-select item-color="PointColor2" :items="selfReports" v-model="selfReport" label="selfReport" outlined dense></v-select>
						</div>
					</v-card>
				</v-flex>
				<v-flex xs12>
					<v-card dark class="pa-2 mb-4">
						<v-layout justify-center>
							<colorPicker v-model="color.MainColor1" :color_picker="color_picker_m1" />
							<colorPicker v-model="color.MainColor2" :color_picker="color_picker_m2" />
						</v-layout>
						<v-layout justify-center>
							<colorPicker v-model="color.FontColor1" :color_picker="color_picker_f1" />
							<colorPicker v-model="color.FontColor2" :color_picker="color_picker_f2" />
						</v-layout>
						<v-layout class="mt-2" justify-center>
							<colorPicker v-model="color.PointColor1" :color_picker="color_picker_p1" />
							<colorPicker v-model="color.PointColor2" :color_picker="color_picker_p2" />
						</v-layout>
						<v-layout class="mt-2" justify-center>
							<colorPicker v-model="color.PointColor3" :color_picker="color_picker_p3" />
						</v-layout>
						<v-layout class="mt-4" justify-end>
							<v-btn color="PointColor1" @click="ResetColorActive" class="mr-2" large>
								Reset
							</v-btn>
							<v-btn color="success" large @click="ApplyColor">
								apply
							</v-btn>
						</v-layout>
					</v-card>
				</v-flex>
			</v-layout>
		</v-flex>
		<v-flex xs12 class="my-4">
			<div class="text-right mb-2" v-if="reportType !== null">
				<v-btn @click="ReportQus.dialog = true" color="success">
					create
				</v-btn>
			</div>
			<v-layout align-center class="mb-2">
				<h2 class="white--text">
					Report Questions
				</h2>
				<v-spacer></v-spacer>
				<div style="max-width:200px;">
					<v-select
						hideDetails
						item-color="PointColor2"
						:items="reportTypes"
						v-model="reportType"
						label="Type"
						solo
						dark
						clearable
						@change="reportQuses"
					></v-select>
				</div>
			</v-layout>
			<datatable :datatable="datatableReport" @showReport="showReport" />
		</v-flex>
		<v-flex xs12 class="mb-4">
			<div class="text-right mb-2">
				<v-btn @click=";(createRuleData.dialog = true), (createRuleData.createType = '')" color="success">
					create
				</v-btn>
			</div>
			<v-layout align-center class="mb-2">
				<h2 class="white--text">
					Vacation Rule
				</h2>
				<v-spacer></v-spacer>
				<textField v-model="search" :textField="SearchTextField" />
			</v-layout>
			<datatable :datatable="datatable" :search="search" @editRule="editRule" @showRule="showRule" />
		</v-flex>
		<v-flex xs12>
			<div class="text-right mb-2">
				<v-btn
					@click="
						;(createVoiceData.dialog = true),
							(createVoiceData.createType = ''),
							(createVoiceData.voice = ''),
							(createVoiceData.version = ''),
							(createVoiceData.type = 'startWork')
					"
					color="success"
				>
					create
				</v-btn>
			</div>
			<v-layout align-center class="mb-2">
				<h2 class="white--text">
					QR Reader Voice Systems
				</h2>
				<v-spacer></v-spacer>
				<textField v-model="voiceSystemSearch" :textField="SearchTextField" />
			</v-layout>
			<datatable
				:datatable="voiceSystemDatatable"
				:search="voiceSystemSearch"
				@showVoiceSystem="showVoiceSystem"
				@soundVoiceSystem="soundVoiceSystem"
			/>
		</v-flex>
		<createReportQus :data="ReportQus" @create="createReportQus" />
		<dialog1 :setDialog="setDialog1" @cancel="setDialog1.dialog = false" @ok="updateSystem" />
		<createRule :data="createRuleData" @create="createRule" />
		<createVoice :data="createVoiceData" @create="createVoiceSystem" />
		<ruleDialog :data="rule" style="display:none;" />
		<input type="file" style="display:none;" id="pic-logo" @change="fileupload" accept="image/*" />
	</v-layout>
</template>
<script>
import { routerIcons, operatingRouterIcons } from './index'
import { textField, datatable, dialog1, createRule, ruleDialog, colorPicker, createReportQus, createVoice } from '@/components'
export default {
	components: {
		textField,
		routerIcons,
		datatable,
		operatingRouterIcons,
		dialog1,
		createRule,
		ruleDialog,
		colorPicker,
		createReportQus,
		createVoice,
	},
	data() {
		return {
			ReportQus: {
				dialog: false,
				qusList: [
					{
						type: '',
						title: '',
						subtitle: '',
						answer: null,
						data: [
							{
								name: '',
							},
						],
					},
				],
			},
			ceoReport: null,
			teamReport: null,
			selfReport: null,
			ceoReports: [0, 1, 2, 4],
			teamReports: [0, 1, 2, 4],
			selfReports: [0, 1, 2, 4],
			logoUrl: null,
			color: {
				MainColor1: '#222326',
				MainColor2: '#04C4D9',
				PointColor1: '#D92B7C',
				PointColor2: '#4C428C',
				PointColor3: '#05F2F2',
				FontColor1: '#F0F1F2',
				FontColor2: '#A8AEBF',
			},
			color_picker_m1: {
				text: 'MainColor 1',
				title: 'MainColor 1',
			},
			color_picker_m2: {
				text: 'MainColor 2',
				title: 'MainColor 2',
			},
			color_picker_p1: {
				text: 'PointColor 1',
				title: 'PointColor 1',
			},
			color_picker_p2: {
				text: 'PointColor 2',
				title: 'PointColor 2',
			},
			color_picker_p3: {
				text: 'PointColor 3',
				title: 'PointColor 3',
			},
			color_picker_f1: {
				text: 'FontColor 1',
				title: 'FontColor 1',
			},
			color_picker_f2: {
				text: 'FontColor 2',
				title: 'FontColor 2s',
			},
			rule: {
				dialog: false,
				title: 'Vaction Rule',
				content: '',
				btnText: 'Vaction Rule',
			},
			createRuleData: {
				dialog: false,
				type: 'Vacation',
				content: '',
				version: '',
				createType: '',
				show: true,
				active: false,
			},
			createVoiceData: {
				dialog: false,
				type: 'startWork',
				version: '',
				createType: 'create',
			},
			setDialog1: {
				dialog: false,
				title: '',
				content: '',
				btnText: '',
			},
			search: '',
			SearchTextField: {
				dark: true,
				width: '200',
				placeholder: 'Search',
				['prepend-inner-icon']: 'mdi-magnify',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			datatable: {
				headers: [
					{
						text: 'NO',
						align: 'center',
						sortable: false,
						value: 'No',
					},
					{ text: 'Type', value: 'type' },
					{ text: 'Version', value: 'version' },
					{ text: 'Creator', value: 'creator' },
					{ text: 'Show', value: 'show' },
					{ text: 'Created_at', value: 'created_at' },
					{ text: 'Updated_at', value: 'updated_at' },
					{ text: 'Action', value: 'ruleAction' },
				],
				items: [],
			},
			voiceSystemSearch: '',
			voiceSystemDatatable: {
				headers: [
					{
						text: 'NO',
						align: 'center',
						sortable: false,
						value: 'No',
					},
					{ text: 'Type', value: 'type' },
					{ text: 'Version', value: 'version' },
					{ text: 'Voice', value: 'voice' },
					{ text: 'Created_at', value: 'created_at' },
					{ text: 'Active', value: 'show' },
					{ text: 'Action', value: 'voiceSystemAction' },
				],
				items: [],
			},
			server: process.env.VUE_APP_BACKEND_URL,
			userID: null,
			logoId: null,
			datatableReport: {
				headers: [
					{
						text: 'NO',
						align: 'center',
						sortable: false,
						value: 'No',
					},
					{ text: 'Type', value: 'type' },
					{ text: 'Title', value: 'title' },
					{ text: 'subTitle', value: 'subtitle' },
					{ text: 'code', value: 'code' },
					{ text: 'Action', value: 'reportAction', sortable: false },
				],
				items: [],
			},
			reportType: null,
			reportTypes: ['ceoReport', 'teamReport', 'selfReport'],
			voiceSystemId: null,
			voiceList: [],
			greetingSpeech: new window.SpeechSynthesisUtterance(),
			synth: window.speechSynthesis,
		}
	},
	methods: {
		voice(voice) {
			this.greetingSpeech.text = `${voice}`
			// 한국어 목소리 선택
			let index = this.voiceList.findIndex(e => e.name === 'Google 한국의')
			this.greetingSpeech.voice = this.voiceList[index]

			this.synth.speak(this.greetingSpeech)
		},
		soundVoiceSystem(val, item) {
			val
			this.voice(item.voice)
		},
		showVoiceSystem(val, item) {
			val
			this.voiceSystemId = item.id
			this.createVoiceData.dialog = true
			this.createVoiceData.type = item.type
			this.createVoiceData.version = item.version
			this.createVoiceData.show = item.show
			this.createVoiceData.voice = item.voice
			this.createVoiceData.createType = 'edit'
		},
		showReport() {
			this.ReportQus.dialog = true
		},
		createReportQus() {
			this.ReportQus.qusList.forEach((element, index) => {
				if (!element.id) {
					const data = {
						listorder: index + 1,
						type: element.type,
						qusType: this.reportType,
						show: true,
						subtitle: element.subtitle,
						title: element.title,
						data: element.data,
						code: element.title + String(index + 1),
					}
					this.$store
						.dispatch('createReportQus', data)
						.then(() => {
							this.ReportQus.dialog = false
							this.reportQuses()
						})
						.catch(err => {
							console.log({ err })
						})
				} else {
					const data = {
						id: element.id,
						listorder: index + 1,
						type: element.type,
						show: true,
						subtitle: element.subtitle,
						title: element.title,
						data: element.data,
						code: element.title + String(index + 1),
					}
					this.$store
						.dispatch('updateReportQus', data)
						.then(() => {
							this.ReportQus.dialog = false
							this.reportQuses()
						})
						.catch(err => {
							console.log({ err })
						})
				}
			})
		},
		reportQuses() {
			if (this.reportType !== null) {
				const data = {
					qusType: this.reportType,
					// show: true,
					// code: String
				}
				this.$store
					.dispatch('reportQuses', data)
					.then(res => {
						this.datatableReport.items = res.reportQuses
						if (res.reportQuses.length > 0) {
							this.ReportQus.qusList = res.reportQuses
						} else {
							this.ReportQus.qusList = [
								{
									type: '',
									title: '',
									subtitle: '',
									answer: null,
									data: [
										{
											name: '',
										},
									],
								},
							]
						}

						console.log(res.reportQuses)
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		LogoUpload() {
			document.getElementById('pic-logo').click()
		},
		fileupload(event) {
			if (event.target.files.length > 0) {
				const data = {
					file: event.target.files[0],
				}
				this.$store
					.dispatch('fileUpload', data)
					.then(res => {
						this.logoId = res.upload.id
						this.logoUrl = res[0].url
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		createSystem() {
			const data = {
				logo: this.logoId,
				color: this.color,
				ceoReport: this.ceoReport,
				teamReport: this.teamReport,
				selfReport: this.selfReport,
			}
			if (!this.logoId) {
				delete data.logo
			}
			this.$store
				.dispatch('createSystem', data)
				.then(res => {
					this.setDialog1.dialog = false
					this.$store.state.systems.id = res.createSystem.system.id
					this.$store.state.systems.color = res.createSystem.system.color
					if (res.updateSystem.system.logo) {
						this.$store.state.systems.logo = res.createSystem.system.logo
					}
					const ColorArray = ['MainColor1', 'MainColor2', 'PointColor1', 'PointColor2', 'PointColor3', 'FontColor1', 'FontColor2']
					ColorArray.forEach(element => {
						this.$store.state[element] = res.createSystem.system.color[element]
						this.$vuetify.theme.themes.light[element] = res.createSystem.system.color[element]
					})
				})
				.catch(err => {
					console.log({ err })
				})
		},
		updateSystem() {
			if (!this.$store.state.systemsNew) {
				const data = {
					logo: this.logoId,
					color: this.color,
					id: this.$store.state.systems.id,
					ceoReport: this.ceoReport,
					teamReport: this.teamReport,
					selfReport: this.selfReport,
				}
				if (!this.logoId) {
					delete data.logo
				}
				this.$store
					.dispatch('updateSystem', data)
					.then(res => {
						this.setDialog1.dialog = false
						this.$store.state.systems.id = res.updateSystem.system.id
						this.$store.state.systems.color = res.updateSystem.system.color
						const ColorArray = ['MainColor1', 'MainColor2', 'PointColor1', 'PointColor2', 'PointColor3', 'FontColor1', 'FontColor2']
						ColorArray.forEach(element => {
							this.$store.state[element] = res.updateSystem.system.color[element]
							this.$vuetify.theme.themes.light[element] = res.updateSystem.system.color[element]
						})
						if (res.updateSystem.system.logo) {
							this.$store.state.systems.logo = res.updateSystem.system.logo
						}
					})
					.catch(err => {
						console.log({ err })
					})
			} else {
				this.createSystem()
			}
		},
		ApplyColor() {
			this.setDialog1.dialog = true
			this.setDialog1.content = 'Are you really sure to Apply Logo And Color?'
			this.setDialog1.title = 'Apply System'
			this.setDialog1.btnText = 'Apply'
		},
		ResetColorActive() {
			this.color = this.$store.state.ResetColor
		},
		showRule(val, item) {
			val
			if (item.type === 'Vacation') {
				this.rule.title = 'Vaction Rule'
				this.rule.btnText = 'Vaction Rule'
			} else {
				this.rule.title = 'Privacy Policy Rule'
				this.rule.btnText = 'Privacy Policy Rule'
			}
			this.rule.content = item.content
			this.rule.dialog = true
		},
		editRule(val, item) {
			val
			this.createRuleData = {
				dialog: true,
				type: item.type,
				content: item.content,
				version: item.version,
				createType: 'edit',
				show: item.show,
				id: item.id,
				active: false,
			}
		},
		rules() {
			const data = {}
			this.$store
				.dispatch('rules', data)
				.then(res => {
					this.datatable.items = res.rules
					this.ceoReport = this.$store.state.systems.ceoReport
					this.teamReport = this.$store.state.systems.teamReport
					this.selfReport = this.$store.state.systems.selfReport
					this.color = this.$store.state.systems.color
					this.logoUrl = this.$store.state.systems.logo.url
				})
				.catch(err => {
					console.log({ err })
				})
		},
		voiceSystems() {
			const data = {}
			this.$store
				.dispatch('voiceSystems', data)
				.then(res => {
					this.voiceSystemDatatable.items = res.voiceSystems
				})
				.catch(err => {
					console.log({ err })
				})
		},
		createVoiceSystem() {
			if (this.createVoiceData.createType === 'edit') {
				this.updateVoiceSystem()
			} else {
				const data = {
					version: Number(this.createVoiceData.version),
					voice: this.createVoiceData.voice,
					type: this.createVoiceData.type,
				}
				this.$store
					.dispatch('createVoiceSystem', data)
					.then(() => {
						this.createVoiceData.dialog = false
						this.voiceSystems()
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		updateVoiceSystem() {
			const data = {
				id: this.voiceSystemId,
				version: Number(this.createVoiceData.version),
				voice: this.createVoiceData.voice,
				type: this.createVoiceData.type,
				show: this.createVoiceData.show,
			}
			this.$store
				.dispatch('updateVoiceSystem', data)
				.then(() => {
					this.createVoiceData.dialog = false
					this.voiceSystems()
				})
				.catch(err => {
					console.log({ err })
				})
		},
		createRule() {
			if (this.createRuleData.createType === 'edit') {
				this.updateRule()
			} else {
				const data = {
					version: Number(this.createRuleData.version),
					content: this.createRuleData.content,
					creator: this.$store.state.myData.email,
					show: true,
					type: this.createRuleData.type,
				}
				this.$store
					.dispatch('createRule', data)
					.then(() => {
						this.createRuleData.dialog = false
						this.rules()
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		updateRule() {
			const data = {
				version: Number(this.createRuleData.version),
				content: this.createRuleData.content,
				creator: this.$store.state.myData.email,
				show: this.createRuleData.show,
				type: this.createRuleData.type,
				id: this.createRuleData.id,
			}
			this.$store
				.dispatch('updateRule', data)
				.then(() => {
					this.createRuleData.dialog = false
					this.rules()
				})
				.catch(err => {
					console.log({ err })
				})
		},
		filesupload(event) {
			this.filedata = event.target.files[0]
			const data = {
				refId: Number(this.$store.state.userData.id),
				field: 'face',
				ref: 'user', // Model name.
				source: 'users-permissions', // Plugin name.
				file: this.filedata,
			}
			this.$store
				.dispatch('fileUpload', data)

				.then(res => {
					this.imgData = res.data.upload.url
				})
				.catch(error => {
					const errorMessage = { error }.error.networkError.result.errors[0].extensions.exception.data.message[0].messages[0].message
					console.log(errorMessage)
				})
		},
		picupload() {
			document.getElementById('pic-upload').click()
		},
	},
	mounted() {
		this.voiceList = this.synth.getVoices()

		this.synth.onvoiceschanged = () => {
			this.voiceList = this.synth.getVoices()
		}
	},
	created() {
		this.voiceSystems()
		this.rules()
	},
}
</script>
<style></style>
