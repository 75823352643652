<template>
	<v-layout justify-center class="main-layout" wrap :style="`background-color:${$store.state.MainColor1};`">
		<routerIcons />
		<operatingRouterIcons />
		<v-flex xs12>
			<div class="text-right mb-2">
				<v-dialog v-model="createDialog" scrollable max-width="300px" dark>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							color="success"
							v-bind="attrs"
							v-on="on"
							:style="
								$store.state.allowLevel.includes(Number($store.state.myData.level))
									? 'display: inline-block !important;'
									: 'display: none !important;'
							"
							v-show="$store.state.allowLevel.includes(Number($store.state.myData.level))"
						>
							create
						</v-btn>
					</template>
					<v-card>
						<v-card-title class="px-4">Create Team</v-card-title>
						<v-divider></v-divider>
						<v-card-text class="px-4 my-2 pb-0">
							<textField v-model="createTeamName" :textField="textField" @input="textField['error-messages'] = ''" />
						</v-card-text>
						<v-divider></v-divider>
						<v-card-actions>
							<v-btn text @click="createDialog = false">
								Close
							</v-btn>
							<v-btn
								color="success"
								text
								@click="createTeam"
								:disabled="!$store.state.allowLevel.includes(Number($store.state.myData.level))"
							>
								Save
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
		</v-flex>
		<v-flex lg4 xs12 v-for="(team, teamIndex) in teamsList" :key="teamIndex">
			<v-card dark class="ma-2">
				<v-card-title class="text-center justify-center py-6">
					<h1 class="white--text">
						{{ team.title }}
					</h1>
					<div
						class="delete-team"
						:style="
							$store.state.allowLevel.includes(Number($store.state.myData.level))
								? 'display: inline-block !important;'
								: 'display: none !important;'
						"
						v-show="$store.state.allowLevel.includes(Number($store.state.myData.level))"
					>
						<v-btn icon color="error" @click="deleteDialog(team.id, team.title)">
							<v-icon>
								mdi-close
							</v-icon>
						</v-btn>
					</div>
				</v-card-title>

				<v-tabs
					v-model="team.tab"
					dark
					grow
					:style="
						$store.state.allowLevel.includes(Number($store.state.myData.level))
							? 'display: inline-block !important;'
							: 'display: none !important;'
					"
					v-if="$store.state.allowLevel.includes(Number($store.state.myData.level))"
				>
					<v-tab v-for="tab in team.tabs" :key="tab">
						{{ tab }}
					</v-tab>
				</v-tabs>

				<v-tabs-items v-model="team.tab" dark class="tab-layout">
					<v-tab-item>
						<v-card dark v-for="element in team.list" :key="element.name" class="pa-2 teamList-Card">
							<v-layout align-center class="ma-2">
								<v-avatar size="56" color="white" class="mr-2">
									<img :src="server + element.face.url" alt="John" v-if="element.face" />
									<img src="/image/img-profile-default-s.png" alt="John" v-else />
								</v-avatar>
								{{ element.Name }} ( Lv.{{ element.level }} )
								<v-spacer></v-spacer>
								<v-btn
									fab
									x-small
									@click="outDialog(team.id, element)"
									color="PointColor2"
									v-if="$store.state.myData.username !== element.username"
								>
									out
								</v-btn>
							</v-layout>
						</v-card>
					</v-tab-item>
					<v-tab-item>
						<v-layout align-center class="ma-2">
							<textField v-model="search" :textField="SearchTextField" />
						</v-layout>
						<datatable :datatable="datatableUsers" :teamList="team.list" :teamId="team.id" :search="search" @join="joinDialog"> </datatable>
					</v-tab-item>
				</v-tabs-items>
			</v-card>
		</v-flex>
		<dialog1 :setDialog="setDialog1" @cancel="setDialog1.dialog = false" @ok="updateTeam" />
	</v-layout>
</template>
<script>
import { routerIcons, operatingRouterIcons } from './index'
import { dialog1, textField, datatable } from '@/components'
export default {
	components: { routerIcons, operatingRouterIcons, dialog1, textField, datatable },
	data() {
		return {
			search: '',
			SearchTextField: {
				dark: false,
				width: '100%',
				placeholder: 'Search',
				['prepend-inner-icon']: 'mdi-magnify',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			textField: {
				width: '100%',
				autofocus: false,
				clearable: true,
				color: '#f7f7f7ff',
				counter: true,
				dark: true,
				disabled: false,
				error: false,
				['error-messages']: '',
				height: 40,
				['hide-details']: false,
				placeholder: 'Team Name',
				solo: true,
				type: 'text',
				maxlength: 50,
			},
			createTeamName: '',
			createDialog: false,
			LevelList: {
				dialog: false,
			},
			setDialog1: {
				dialog: false,
				title: 'Join my Team',
				content: '',
				btnText: 'Ask Join',
				type: '',
			},
			server: process.env.VUE_APP_BACKEND_URL,
			teamsList: [],
			datatableUsers: {
				headers: [
					{ text: 'Name', value: 'Name' },
					{ text: 'position', value: 'position' },
					{ text: 'Join', value: 'join' },
				],
				items: [],
				hidedefaultfooter: true,
				hidedefaultheader: true,
			},
			joinUser: {},
			outUser: {},
		}
	},
	methods: {
		outDialog(teamId, item) {
			this.setDialog1.type = 'out'
			this.teamId = teamId
			this.outUser = item
			this.setDialog1.dialog = true
			this.setDialog1.title = 'Out my Team'
			this.setDialog1.content = 'Are you really sure to Out ' + item.Name + ' to the team?'
			this.setDialog1.btnText = 'Out'
		},
		deleteDialog(teamId, teamName) {
			this.setDialog1.type = 'delete'
			this.teamId = teamId
			this.setDialog1.dialog = true
			this.setDialog1.title = 'Join my Team'
			this.setDialog1.content = 'Are you really sure to delete ' + teamName + ' to the team?'
			this.setDialog1.btnText = 'Join'
		},
		joinDialog(event, item, teamId) {
			console.log(event)
			this.joinUser = item
			this.setDialog1.type = 'join'
			this.teamId = teamId
			this.setDialog1.dialog = true
			this.setDialog1.title = 'Join my Team'
			this.setDialog1.content = 'Are you really sure to thinking of recruiting ' + item.Name + ' to the team?'
			this.setDialog1.btnText = 'Ask Join'
		},
		deleteTeam() {
			if (this.teamId) {
				const data = {
					id: this.teamId,
					status: 99,
					users: [],
				}
				this.$store
					.dispatch('updateTeam', data)
					.then(() => {
						this.teams()
					})
					.catch(err => {
						console.log({ err })
					})
			} else {
				alert('error')
			}
		},
		updateTeam() {
			if (this.setDialog1.type === 'delete') {
				this.deleteTeam()
			} else if (this.setDialog1.type === 'join') {
				const users = this.teamsList[this.teamsList.map(x => x.id).indexOf(this.teamId)].list.map(y => y.id)
				const teamPosition = this.teamsList[this.teamsList.map(x => x.id).indexOf(this.teamId)].teamPosition
				teamPosition.push({
					level: this.joinUser.level,
					status: 'join',
					name: this.joinUser.Name,
					username: this.joinUser.username,
					joinDate: new Date(),
				})
				const data = {
					id: this.teamId,
					users: [...users, this.joinUser.id],
					teamPosition: teamPosition,
				}
				this.$store
					.dispatch('updateTeam', data)
					.then(() => {
						this.teams()
					})
					.catch(err => {
						console.log(err)
					})
			} else if (this.setDialog1.type === 'out') {
				const users = this.teamsList[this.teamsList.map(x => x.id).indexOf(this.teamId)].list.map(y => y.id)
				const teamPosition = this.teamsList[this.teamsList.map(x => x.id).indexOf(this.teamId)].teamPosition
				users.splice(users.indexOf(this.outUser.id), 1)
				teamPosition.push({
					level: this.outUser.level,
					status: 'leave',
					name: this.outUser.Name,
					username: this.outUser.username,
					outDate: new Date(),
				})
				const data = {
					id: this.teamId,
					users: users,
					teamPosition: teamPosition,
				}
				this.$store
					.dispatch('updateTeam', data)
					.then(() => {
						this.teams()
					})
					.catch(err => {
						console.log(err)
					})
			}
		},
		createTeam() {
			if (this.$store.state.myData.id && this.$store.state.allowLevel.includes(Number(this.$store.state.myData.level))) {
				if (this.createTeamName === '') {
					this.textField['error-messages'] = 'please input the text name'
					return
				}
				const data = {
					users: [this.$store.state.myData.id],
					title: this.createTeamName,
					teamPosition: [
						{
							leader: this.$store.state.myData.username,
							level: this.$store.state.myData.level,
							status: 'join',
							name: this.$store.state.myData.Name,
							username: this.$store.state.myData.username,
							joinDate: new Date(),
						},
					],
				}
				this.$store
					.dispatch('createTeam', data)
					.then(() => {
						this.teams()
						this.createTeamName = ''
					})
					.catch(err => {
						console.log(err)
					})
			} else {
				alert('me data is empty')
			}
		},
		teams() {
			this.$store
				.dispatch('teams')
				.then(res => {
					this.setDialog1.dialog = false
					this.createDialog = false
					this.teamsList = []
					res.teams.forEach((element, index) => {
						this.teamsList.push({
							id: element.id,
							index: index,
							tab: 0,
							tabs: ['Team List', 'join team'],
							title: element.title,
							list: element.users_permissions_users,
							teamPosition: element.teamPosition,
						})
					})
				})
				.catch(err => {
					console.log({ err })
				})
		},
		allUsers() {
			const data = {}
			this.$store
				.dispatch('allUsers', data)
				.then(res => {
					console.log(res.users)
					this.datatableUsers.items = res.users
				})
				.catch(err => {
					console.log({ err })
				})
		},
		resetPassword() {},
		create() {},
	},
	created() {
		this.teams()
		this.allUsers()
	},
}
</script>
<style>
.tab-layout {
	max-height: 500px;
	overflow-y: auto;
	overflow-x: hidden;
}
.teamList-Card {
	border-top: 0.5px solid lightgrey !important;
	border-bottom: 0.5px solid lightgrey !important;
}
.delete-team {
	position: absolute;
	right: 10px;
}
</style>
