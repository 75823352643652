var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"main-layout",style:(("background-color:" + (_vm.$store.state.MainColor1) + ";")),attrs:{"justify-center":"","wrap":""}},[_c('routerIcons'),_c('operatingRouterIcons'),_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"text-right mb-2"},[_c('v-dialog',{attrs:{"scrollable":"","max-width":"300px","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.allowLevel.includes(Number(_vm.$store.state.myData.level))),expression:"$store.state.allowLevel.includes(Number($store.state.myData.level))"}],style:(_vm.$store.state.allowLevel.includes(Number(_vm.$store.state.myData.level))
								? 'display: inline-block !important;'
								: 'display: none !important;'),attrs:{"color":"success"}},'v-btn',attrs,false),on),[_vm._v(" create ")])]}}]),model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"px-4"},[_vm._v("Create Team")]),_c('v-divider'),_c('v-card-text',{staticClass:"px-4 my-2 pb-0"},[_c('textField',{attrs:{"textField":_vm.textField},on:{"input":function($event){_vm.textField['error-messages'] = ''}},model:{value:(_vm.createTeamName),callback:function ($$v) {_vm.createTeamName=$$v},expression:"createTeamName"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.createDialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"success","text":"","disabled":!_vm.$store.state.allowLevel.includes(Number(_vm.$store.state.myData.level))},on:{"click":_vm.createTeam}},[_vm._v(" Save ")])],1)],1)],1)],1)]),_vm._l((_vm.teamsList),function(team,teamIndex){return _c('v-flex',{key:teamIndex,attrs:{"lg4":"","xs12":""}},[_c('v-card',{staticClass:"ma-2",attrs:{"dark":""}},[_c('v-card-title',{staticClass:"text-center justify-center py-6"},[_c('h1',{staticClass:"white--text"},[_vm._v(" "+_vm._s(team.title)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.allowLevel.includes(Number(_vm.$store.state.myData.level))),expression:"$store.state.allowLevel.includes(Number($store.state.myData.level))"}],staticClass:"delete-team",style:(_vm.$store.state.allowLevel.includes(Number(_vm.$store.state.myData.level))
							? 'display: inline-block !important;'
							: 'display: none !important;')},[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteDialog(team.id, team.title)}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1)]),(_vm.$store.state.allowLevel.includes(Number(_vm.$store.state.myData.level)))?_c('v-tabs',{style:(_vm.$store.state.allowLevel.includes(Number(_vm.$store.state.myData.level))
						? 'display: inline-block !important;'
						: 'display: none !important;'),attrs:{"dark":"","grow":""},model:{value:(team.tab),callback:function ($$v) {_vm.$set(team, "tab", $$v)},expression:"team.tab"}},_vm._l((team.tabs),function(tab){return _c('v-tab',{key:tab},[_vm._v(" "+_vm._s(tab)+" ")])}),1):_vm._e(),_c('v-tabs-items',{staticClass:"tab-layout",attrs:{"dark":""},model:{value:(team.tab),callback:function ($$v) {_vm.$set(team, "tab", $$v)},expression:"team.tab"}},[_c('v-tab-item',_vm._l((team.list),function(element){return _c('v-card',{key:element.name,staticClass:"pa-2 teamList-Card",attrs:{"dark":""}},[_c('v-layout',{staticClass:"ma-2",attrs:{"align-center":""}},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"56","color":"white"}},[(element.face)?_c('img',{attrs:{"src":_vm.server + element.face.url,"alt":"John"}}):_c('img',{attrs:{"src":"/image/img-profile-default-s.png","alt":"John"}})]),_vm._v(" "+_vm._s(element.Name)+" ( Lv."+_vm._s(element.level)+" ) "),_c('v-spacer'),(_vm.$store.state.myData.username !== element.username)?_c('v-btn',{attrs:{"fab":"","x-small":"","color":"PointColor2"},on:{"click":function($event){return _vm.outDialog(team.id, element)}}},[_vm._v(" out ")]):_vm._e()],1)],1)}),1),_c('v-tab-item',[_c('v-layout',{staticClass:"ma-2",attrs:{"align-center":""}},[_c('textField',{attrs:{"textField":_vm.SearchTextField},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('datatable',{attrs:{"datatable":_vm.datatableUsers,"teamList":team.list,"teamId":team.id,"search":_vm.search},on:{"join":_vm.joinDialog}})],1)],1)],1)],1)}),_c('dialog1',{attrs:{"setDialog":_vm.setDialog1},on:{"cancel":function($event){_vm.setDialog1.dialog = false},"ok":_vm.updateTeam}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }