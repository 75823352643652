<template>
	<v-layout justify-center class="main-layout" wrap :style="`background-color:${$store.state.MainColor1};`">
		<routerIcons />
		<operatingRouterIcons />
		<v-flex xs12>
			<v-layout align-center class="mb-2">
				<h2 class="white--text">
					co-Worker Statistics
				</h2>
				<v-spacer></v-spacer>
				<textField v-model="search" :textField="SearchTextField" @keydown.enter="userSearch(search)" />
			</v-layout>
			<v-layout wrap justify-start>
				<v-flex lg3 md4 sm6 xs12 v-for="(data, i) in workCards" :key="i">
					<workCard :data="data" @editVacation="editVacation" @ceoReport="ceoReport" />
				</v-flex>
				<v-flex xs12>
					<div class="text-center">
						<v-pagination color="PointColor1" v-model="coWorkerPage" :length="coWorkerPageLength" circle @input="paging"></v-pagination>
					</div>
				</v-flex>
			</v-layout>
		</v-flex>
		<v-flex xs12>
			<div :class="$vuetify.breakpoint.smAndDown ? '' : 'mx-2'">
				<h2 class="white--text">
					Project Statistics
				</h2>
				<v-layout align-center class="my-2">
					<DatepickerRange :picker="projectStartpicker" @input="projectDate"></DatepickerRange>
					<span class="mx-3 white--text"> ~ </span>
					<DatepickerRange :picker="projectEndpicker" :allowed_dates="projectCouselor" @input="projectDate"></DatepickerRange>
					<v-spacer></v-spacer>
					<textField v-model="Projectsearch" :textField="SearchTextField" />
				</v-layout>
				<datatable :datatable="datatable" :search="Projectsearch" @detailProjectShow="detailProjectShow" />
			</div>
		</v-flex>
		<dialog1 :setDialog="setDialog1" @cancel="setDialog1.dialog = false" />
		<v-dialog v-model="editVacationDialog.dialog" max-width="500px">
			<v-card>
				<v-card-title>
					edit Vacation Days
				</v-card-title>
				<v-card-text>{{ editVacationDialog.content }}</v-card-text>
				<v-layout justify-center class="mb-2">
					<textField v-model="editVacationDialog.days" :textField="editVacationTextField" class="mx-6" />
				</v-layout>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="PointColor2" text @click="editVacationDialog.dialog = false">
						cancel
					</v-btn>
					<v-btn color="PointColor2" @click="updateUser" dark>
						edit
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<detailProject :data="projectDetail" />
		<ceoReportDialog :data="ceoReportDialog" />
	</v-layout>
</template>
<script>
import { routerIcons, operatingRouterIcons } from './index'
import { textField, datatable, dialog1, workCard, DatepickerRange, detailProject, ceoReportDialog } from '@/components'
export default {
	components: {
		textField,
		routerIcons,
		datatable,
		operatingRouterIcons,
		dialog1,
		workCard,
		DatepickerRange,
		detailProject,
		ceoReportDialog,
	},
	data() {
		return {
			ceoReportDialog: {
				dialog: false,
				item: {
					monthWorks: [
						{ [1]: 0 },
						{ [2]: 0 },
						{ [3]: 0 },
						{ [4]: 0 },
						{ [5]: 0 },
						{ [6]: 0 },
						{ [7]: 0 },
						{ [8]: 0 },
						{ [9]: 0 },
						{ [10]: 0 },
						{ [11]: 0 },
						{ [12]: 0 },
					],
				},
			},
			projectDetail: {
				dialog: false,
				project: {
					name: '',
					teams: [],
				},
				team: {
					title: '',
				},
			},
			projectStartpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'start',
				height: 48,
				width: 200,
			},
			projectEndpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'end',
				height: 48,
				width: 200,
			},
			Projectsearch: '',
			editVacationTextField: {
				dark: false,
				autofocus: false,
				placeholder: 'Vacation Days',
				solo: true,
				type: 'Number',
				max: 365,
			},
			coWorkerPageLength: 1,
			coWorkerPage: 1,
			workCards: [],
			setDialog1: {
				dialog: false,
				title: 'PASSWORD RESET',
				content: 'The password is changed to "TEST1234"',
				btnText: 'PASSWORD RESET',
			},
			search: '',
			SearchTextField: {
				dark: true,
				width: '200',
				placeholder: 'Search',
				['prepend-inner-icon']: 'mdi-magnify',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			datatable: {
				headers: [
					{
						text: 'NO',
						align: 'center',
						sortable: false,
						value: 'No',
					},
					{ text: 'Period', value: 'period', sortable: false },
					{ text: 'Project Title', value: 'name' },
					{ text: 'Manager', value: 'pmNameStatistics' },
					{ text: 'Request', value: 'todolist1' },
					{ text: 'Checking', value: 'todolist2' },
					{ text: 'Finsih', value: 'todolist3' },
					{ text: 'Project View', value: 'ProjectView', sortable: false },
				],
				items: [],
			},
			server: process.env.VUE_APP_BACKEND_URL,
			register: {
				dialog: false,
				username: '',
				password: '',
				name: '',
				phone: '',
				email: '',
				position: '',
				edit: false,
				startPicker: {
					date: null,
					rounded: true,
					errorMessage: '',
					menu: false,
					placeholder: 'start Date',
					height: 56,
				},
				endPicker: {
					date: null,
					rounded: true,
					errorMessage: '',
					menu: false,
					placeholder: 'retirement',
					height: 56,
				},
			},
			userID: null,
			editVacationDialog: {
				dialog: false,
				content: 'edit Vacation Days',
				days: 0,
			},
			editUser: {},
		}
	},
	methods: {
		ceoReport(val, item) {
			val
			console.log(item)
			this.ceoReportDialog.dialog = true
			this.ceoReportDialog.item = item
		},
		detailProjectShow(val, project) {
			val
			this.projectDetail.project = project
			this.projectDetail.dialog = true
			if (project.team) {
				this.projectDetail.team = {
					id: project.team.id,
					tab: 0,
					tabs: ['Team List', 'join team'],
					title: project.team.title,
					list: project.team.users_permissions_users,
					teamPosition: project.team.teamPosition,
				}
			} else {
				this.projectDetail.team = {}
			}
		},
		todolists(projectDatas) {
			const data = {
				createDay_gte: this.projectStartpicker.date,
				createDay_lte: this.projectEndpicker.date,
			}
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					const projects = projectDatas
					res.todolists.forEach(element => {
						const index = projects.map(x => x.interID).indexOf(element.interID)
						if (index > -1) {
							projects[index].todolist = element
						}
					})
					this.datatable.items = projects
				})
				.catch(err => {
					console.log({ err })
				})
		},
		projectDate() {
			this.projectStartpicker.menu = false
			this.projectEndpicker.menu = false
			this.calendarsStatistics(this.projectStartpicker.date, this.projectEndpicker.date)
		},
		projectCouselor(val) {
			let start = this.$moment(this.projectStartpicker.date).format('YYMMDD')

			let startDate = new Date(this.projectStartpicker.date)
			let end = new Date(startDate.setDate(startDate.getDate() + 3650))
			let endDate = this.$moment(end).format('YYMMDD')

			val = this.$moment(val).format('YYMMDD') < endDate && this.$moment(val).format('YYMMDD') > start
			return val
		},
		calendarsStatistics() {
			const data = {
				start: this.projectStartpicker.date,
				end: this.projectEndpicker.date,
			}
			this.$store
				.dispatch('calendarsStatistics', data)
				.then(res => {
					this.todolists(res.calendars)
				})
				.catch(err => {
					console.log({ err })
				})
		},
		updateUser() {
			const data = {
				id: this.editUser.id,
				vacationsDays: Number(this.editVacationDialog.days),
			}
			this.$store
				.dispatch('updateUser', data)
				.then(() => {
					this.allUsers()
					this.editVacationDialog.dialog = false
				})
				.catch(err => {
					console.log({ err })
				})
		},
		editVacation(val, item) {
			val
			this.editUser = item
			this.editVacationDialog.dialog = true
			this.editVacationDialog.content = item.Name + ' edit Vacation Days'
			this.editVacationDialog.days = item.vacationsDays
		},
		userSearch(search) {
			this.allUsers(search)
		},
		usersCount() {
			this.$store
				.dispatch('usersCount')
				.then(res => {
					this.coWorkerPageLength = Math.ceil(res.usersCount / 10)
					this.allUsers()
				})
				.catch(err => {
					console.log({ err })
				})
		},
		paging() {
			this.allUsers()
		},
		Attendancelist(users) {
			const data = {
				workday_gte: this.$moment(new Date()).format('YYYY-') + '01-01',
			}
			this.$store
				.dispatch('Attendancelist', data)
				.then(res => {
					this.workCards = []
					users.forEach(element => {
						this.workCards.push({
							id: element.id,
							monthWorkTime: 168,
							holiayDayTime: 0,
							holiayDay: 0,
							Name: element.Name,
							startdate: element.startdate,
							vacationsDays: element.vacationsDays,
							thisYearTotalWorkDays: 0,
							thisYearTotalWorkTime: 0,
							thisMonthTotalWorkDays: 0,
							totalVacationDays: 0,
							interID: element.interID,
							monthWorks: [
								{ [1]: 0 },
								{ [2]: 0 },
								{ [3]: 0 },
								{ [4]: 0 },
								{ [5]: 0 },
								{ [6]: 0 },
								{ [7]: 0 },
								{ [8]: 0 },
								{ [9]: 0 },
								{ [10]: 0 },
								{ [11]: 0 },
								{ [12]: 0 },
							],
							bage: 0,
							Attendancelist: {
								dialog: false,
								btnText: 'work List',
								class: 'mr-2',
								interID: element.interID,
							},
						})
					})
					const setInterID = users.map(x => x.interID)
					res.attendances.forEach((attendance, attendanceIndex) => {
						const index = setInterID.indexOf(attendance.interID)
						if (this.$moment(attendance.work).day() !== 0 && this.$moment(attendance.work).day() !== 6) {
							let t2
							if (attendance.Leave) {
								t2 = this.$moment(attendance.Leave)
							} else {
								t2 = this.$moment(attendance.work).add(6, 'hours')
							}
							const t1 = this.$moment(attendance.work)
							if (this.workCards[index]) {
								this.workCards[index].thisYearTotalWorkTime += Math.round(t2.diff(t1, 'minutes') / 60)
								this.workCards[index].thisYearTotalWorkDays += 1
								this.workCards[index].monthWorks[Number(this.$moment(attendance.work).format('MM')) - 1][
									Number(this.$moment(attendance.work).format('MM'))
								] += t2.diff(t1, 'minutes') / 60
								if (this.$moment(attendance.work).format('MM') === this.$moment().format('MM')) {
									this.workCards[index].thisMonthTotalWorkDays += 1
								}
							}
						} else {
							this.workCards[index].holiayDay += 1
							let t3 = 0
							if (attendance.Leave) {
								t3 = this.$moment(attendance.Leave)
							} else {
								t3 = this.$moment(attendance.work).add(6, 'hours')
							}
							const t4 = this.$moment(attendance.work)
							this.workCards[index].holiayDayTime += Math.round(t3.diff(t4, 'minutes') / 60)
						}
						if (attendanceIndex + 1 === res.attendances.length) {
							users.forEach(user => {
								const indexUser = setInterID.indexOf(user.interID)
								if (user.startdate) {
									const notYears = this.$moment(this.$moment().format('YYYY-MM-DD')).isBetween(
										this.$moment(user.startdate).format('YYYY-MM-DD'),
										this.$moment(user.startdate)
											.add(1, 'years')
											.format('YYYY-MM-DD'),
										undefined,
										'[]',
									)
									if (notYears) {
										this.workCards[indexUser].monthWorks.forEach((month, indexMonth) => {
											if (month[indexMonth + 1] > this.workCards[indexUser].monthWorkTime * 0.8) {
												this.workCards[indexUser].totalVacationDays += 1
											}
										})
									} else {
										const y1 = this.$moment(user.startdate)
										const y2 = this.$moment()
										const years = y2.diff(y1, 'months', true)
										this.workCards[indexUser].totalVacationDays = 15 + Math.floor((Math.floor(years / 12) - 1) / 2)
									}
								}
							})
						}
					})
				})
				.catch(err => {
					console.log({ err })
				})
		},
		allUsers(search) {
			let data
			if (search) {
				data = {
					start: (this.coWorkerPage - 1) * 10,
					limit: 10,
					Name: search,
				}
			} else {
				data = {
					start: (this.coWorkerPage - 1) * 10,
					limit: 10,
				}
			}
			this.$store
				.dispatch('allUsers', data)
				.then(res => {
					if (res.users.length > 0) {
						this.Attendancelist(res.users)
					} else {
						this.workCards = []
					}
				})
				.catch(err => {
					console.log({ err })
				})
		},
	},
	created() {
		this.projectStartpicker.date = this.$moment(new Date())
			.subtract(180, 'days')
			.format('YYYY-MM-DD')
		this.projectEndpicker.date = this.$moment(new Date())
			.add(90, 'days')
			.format('YYYY-MM-DD')
		this.calendarsStatistics()
		this.usersCount()
	},
}
</script>
<style></style>
